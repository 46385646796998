// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ahorrar-aparcamiento-barcelona-js": () => import("../src/pages/ahorrar-aparcamiento-barcelona.js" /* webpackChunkName: "component---src-pages-ahorrar-aparcamiento-barcelona-js" */),
  "component---src-pages-contaje-gratis-js": () => import("../src/pages/contaje-gratis.js" /* webpackChunkName: "component---src-pages-contaje-gratis-js" */),
  "component---src-pages-descargar-descargar-app-js": () => import("../src/pages/descargar/descargar-app.js" /* webpackChunkName: "component---src-pages-descargar-descargar-app-js" */),
  "component---src-pages-descargar-formulario-1-js": () => import("../src/pages/descargar/formulario-1.js" /* webpackChunkName: "component---src-pages-descargar-formulario-1-js" */),
  "component---src-pages-descargar-formulario-2-js": () => import("../src/pages/descargar/formulario-2.js" /* webpackChunkName: "component---src-pages-descargar-formulario-2-js" */),
  "component---src-pages-descargar-gracias-js": () => import("../src/pages/descargar/gracias.js" /* webpackChunkName: "component---src-pages-descargar-gracias-js" */),
  "component---src-pages-encontrar-parking-barcelona-js": () => import("../src/pages/encontrar-parking-barcelona.js" /* webpackChunkName: "component---src-pages-encontrar-parking-barcelona-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parkings-js": () => import("../src/pages/parkings.js" /* webpackChunkName: "component---src-pages-parkings-js" */),
  "component---src-pages-politica-de-cookies-js": () => import("../src/pages/politica-de-cookies.js" /* webpackChunkName: "component---src-pages-politica-de-cookies-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */)
}

